import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Image from 'components/Image';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import BugImg from '../../images/bug.svg';
import Link from '@mui/material/Link';
import Head from '../Header/Head';
import FixedBackgroundImage from './FixedBackgroundImage';
import { useState } from 'react';
import LoginButton from '../Header/LoginButton';

const ErrorPage = props => {
  const [loginOpen, setLoginOpen] = useState(false);

  const { statusCode, message } = props;

  let error = {
    title: `Ooops... That shouldn't have happened`,
    message: (
      <>
        <div className="container">
          <p>
            Looks like there&apos;s a problem with TravelFeed. Please help us by{' '}
            <Link href="/about/contact">reporting this issue</Link> and letting
            us know what lead to this error.
          </p>
          <div>
            <Image
              priority
              alt="Bad developer"
              src={BugImg}
              height={300}
              width={300}
            />
          </div>
          <Link href="/about/contact">
            <Button color="primary" variant="contained">
              Report this bug
            </Button>
          </Link>
        </div>
      </>
    ),
  };
  if (statusCode === 'logged_out')
    error = {
      title: 'Error: Logged Out',
      message: 'You need to log in to access this page.',
    };
  else if (statusCode === 'offline')
    error = {
      title: 'Offline',
      message: 'Connect to a network to load this content.',
    };
  else if (statusCode === 'network_error')
    error = {
      title: 'Network Error',
      message: 'Are you online?',
    };
  else if (statusCode === 404)
    error = {
      title: '404: Not Found',
      message: 'This page could not be found.',
    };
  else if (statusCode === 'invalid_login')
    error = {
      title: 'Invalid login credentials',
      message:
        message ||
        'The login credentials you provided are not valid. Please log in again.',
    };
  else if (statusCode === 'sign_up_fail')
    error = {
      title: 'Sign Up Failed',
      message: message || 'Please try again',
    };
  else if (statusCode === 'confirm_email')
    error = {
      title: 'Confirm your Email',
      message: 'We sent you an email with a link to activate your account.',
    };
  else if (statusCode === 'communityNotWhitelisted')
    error = {
      title: 'Community not whitelisted',
      message: (
        <>
          This community is not yet whitelisted to be viewed on TravelFeed. If
          you own this community and believe that it is travel-related, please
          send us an email at{' '}
          <Typography color="primary" display="inline">
            <a
              href="mailto:contact@travelfeed.com"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              contact@travelfeed.com
            </a>
          </Typography>
        </>
      ),
    };
  else if (statusCode === 'exit_url')
    error = {
      title: 'Warning: External Link',
      message:
        'This is an external link. Please check it carefully before proceeding.',
      footer: (
        <>
          <div className="text-center pb-3">
            <em>{props.url}</em>
          </div>
          <Button
            onClick={() => Router.back()}
            color="primary"
            variant="outlined"
            className="m-1"
          >
            Go back
          </Button>
          <a
            rel="ugc nofollow noopener noreferrer"
            target="_blank"
            href={props.url}
          >
            <Button color="primary" variant="contained" className="m-1">
              Visit this Website
            </Button>
          </a>
        </>
      ),
    };
  return (
    <>
      <Head
        title={`${error.title} | TravelFeed`}
        noIndex
        noImage
        noFollow={statusCode === 'exit_url'}
      />
      <FixedBackgroundImage
        backgroundImage="https://img.travelfeed.io/jpphotography%2F20190928T190403125Z-easysignup-2.jpg"
        component={
          <>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{
                minHeight: 'calc(100vh - 85px)',
                marginBottom: '10px',
              }}
            >
              <Grid item lg={7} md={8} sm={11} xs={11}>
                <Card>
                  <CardContent>
                    <div className="text-center">
                      <Typography variant="h4" gutterBottom>
                        {error.title}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {error.message}
                      </Typography>
                      <div className="pt-2">
                        {error.footer ||
                          (statusCode === 'logged_out' ? (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => setLoginOpen(true)}
                            >
                              Login
                            </Button>
                          ) : (
                            <Link href="/">
                              <Button color="primary" variant="contained">
                                Back to Homepage
                              </Button>
                            </Link>
                          ))}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        }
      />
      {loginOpen && (
        <LoginButton
          loginOpen={loginOpen}
          hideButtons
          onClickClose={() => setLoginOpen(false)}
        />
      )}
    </>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorPage;
